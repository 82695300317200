import { urlPathnameToLocationName } from '@/features/shared/analytics/utils'
import { type ExperimentType } from '@/features/shared/services/Experiments/constants'
import {
  type ExperimentValue,
  type AristotleTrack,
} from '@/features/shared/services/Experiments/types'
import { useUser } from '@/features/account/services/User/hooks'
import { apiPost } from '@/features/shared/utils/dataFetching'
import { removeFalseyValues } from '@/features/shared/utils/removeFalseyValues'
import { useMutation } from '@tanstack/react-query'
import { getMutationCacheStatus } from '@/features/shared/utils/dataFetching/reactQuery/getMutationData'
import { getUniversalId } from '@/features/shared/services/Experiments/utils'
import { getAnonymousId } from '@shipt/analytics-member-web'
import { useGlobalDefaultShoppingAddress } from '@/features/account/services/Addresses/hooks'
import { removeNilValues } from '@/features/shared/utils/removeNilValues'
import { MembershipStatus } from '@/features/account/services/MembershipPlan/types'

const MutationExperimentsTrackKey = 'Track Experiments'

/**
 * Sends a tracking event to aristotle. Used when a member enters into an experiment.
 * @param key - the experiment key in optimizely
 */
export const useMutationTrackExperiment = (key: ExperimentType) => {
  const user = useUser()
  const { zip_code: zip } = useGlobalDefaultShoppingAddress()
  const {
    id,
    metro_id,
    store_id,
    store_location_id,
    metro,
    store,
    has_placed_first_order,
    has_purchased,
  } = user || {}
  return useMutation({
    mutationKey: [MutationExperimentsTrackKey, { key }],
    scope: { id: key },
    mutationFn: (value: ExperimentValue) => {
      const isSuccessfullyTracked =
        getMutationCacheStatus([MutationExperimentsTrackKey, { key }]) ===
        'success'

      if (isSuccessfullyTracked || !value) {
        // not in experiment OR already tracked OR data not yet loaded: early exit.
        return Promise.resolve()
      }

      const universal_id = getUniversalId({ user })
      const anonymous_id = getAnonymousId()

      // aristotle doesn't like 0 user_id
      const user_id = id === 0 ? '' : id?.toString()
      const track: AristotleTrack = removeFalseyValues({
        key,
        variation: value,
        user_id,
        universal_id,
        anonymous_id,
        page_source: urlPathnameToLocationName(),
        user_traits: removeNilValues({
          platform: 'web',
          signed_in: Boolean(id),
          metro: metro?.name,
          metro_id: metro_id ?? 0,
          store: store?.name,
          store_id: store_id ?? 0,
          store_location_id: store_location_id ?? 0,
          has_placed_first_order,
          has_purchased,
          has_subscription:
            user?.subscription?.status === MembershipStatus.active,
          is_exempt: Boolean(user?.exempt),
          zip,
          plan_id: user?.subscription?.plan?.id,
          subscription_type: user?.subscription?.type,
        }),
      })

      // Visitors do not have a universal_id until they create an account,
      // in order to run experiments on ungated guest experiences,
      // we need to account for this and check for anonymous_id.
      const hasId = user_id || universal_id || anonymous_id

      if (!track.key || !track.variation || !hasId) return Promise.resolve()
      return apiPost<void>({
        config: {
          url: 'aristotle/v2/track',
          data: track,
        },
        fetcherName: 'useMutationTrackExperiment',
      })
    },
  })
}
