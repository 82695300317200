import { type ReactNode, useCallback, useRef, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useIntersectionObserver } from '@/features/shared/hooks/useIntersectionObserver'

// For element impressions, a element is considered in viewport when 50% of it scrolls into the screen.
// This heuristic is determined by Shipt's Data Science team.
const SCROLL_THRESHOLD = 0.5

type Props = {
  threshold?: number
  children: ReactNode
  className?: string
}

export type ScrollIntoViewProps = Props & {
  // If true, the container will use auto height and width
  autoSize?: boolean
  onInView: () => void
  resetOnUnmount?: boolean
  component?: keyof HTMLElementTagNameMap
}

export const ScrollIntoView = ({
  autoSize,
  onInView,
  className,
  threshold = SCROLL_THRESHOLD,
  children,
  resetOnUnmount,
  component = 'div',
}: ScrollIntoViewProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const scrolledIntoView = useRef<boolean>(false)

  const handleInView = useCallback(
    (observerEntries: IntersectionObserverEntry[]) => {
      if (scrolledIntoView.current) return

      const ratio = observerEntries[0]?.intersectionRatio
      if (ratio !== undefined && ratio >= threshold) {
        scrolledIntoView.current = true
        onInView()
      }
    },
    [onInView, threshold]
  )

  useEffect(() => {
    return () => {
      if (resetOnUnmount) scrolledIntoView.current = false
    }
  }, [resetOnUnmount])

  useIntersectionObserver(ref, {
    threshold,
    callback: handleInView,
  })

  return (
    <ScrollIntoViewContainer
      className={className}
      ref={ref}
      as={component}
      $autoSize={autoSize}
    >
      {children}
    </ScrollIntoViewContainer>
  )
}

const ScrollIntoViewContainer = styled.div<{ $autoSize?: boolean }>`
  height: ${({ $autoSize }) => ($autoSize ? 'auto' : '100%')};
  width: ${({ $autoSize }) => ($autoSize ? 'auto' : '100%')};
`

type ObserveProps = Props & {
  rootMargin?: string
  freezeOnceVisible?: boolean
  eagerLoad?: boolean
}

export const LazyLoadObserve = ({
  rootMargin = '100px',
  threshold = 0.01,
  children,
  className,
  freezeOnceVisible = true,
  eagerLoad = false,
  ...restProps
}: ObserveProps) => {
  const [inView, setInView] = useState(eagerLoad)
  const ref = useRef<HTMLDivElement>(null)

  const observeScroll = useCallback(
    (observerEntries: IntersectionObserverEntry[]) => {
      const ratio = observerEntries[0]?.intersectionRatio
      const isViewable = ratio !== undefined && ratio >= threshold
      setInView(isViewable)
    },
    [threshold]
  )

  useIntersectionObserver(ref, {
    rootMargin,
    threshold,
    callback: observeScroll,
    frozen: freezeOnceVisible && inView,
  })

  return (
    <div ref={ref} className={className} {...restProps}>
      {inView ? children : null}
    </div>
  )
}
